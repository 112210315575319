export const common = {
	promotionId: 'Promotion ID',
	programId: 'Program ID',
	promotionEndDate: 'Promotion End Date',
	couponId: 'Coupon ID',
	couponType: 'Coupon Type',
	couponEndDate: 'Coupon End Date',
	couponExpiryDate: 'Coupon Expiry Date',
	ok: 'Ok',
	close: 'Close',
	active: 'Active',
	inactive: 'Inactive',
	expired: 'Expired',
	cancel: 'Cancel',
	download: 'Download',
	nothingToShow: 'Nothing to show',
	copyToClipboard: 'Copy to Clipboard',
	copyToClipboardSussesMsg: 'Information copied to clipboard!',
	copyIconTitle: 'Copy Request ID',
	status: 'Status',
	redeem: 'Redeem',
	unredeem: 'Unredeem',
	back: 'Back',
	reset: 'Reset',
	submit: 'Submit',
	replicate: 'Replicate',
	downloadsFailed: 'Download Failed',
	information: 'Information',
	search: 'Search',
	previous: 'Previous',
	next: 'Next',
	add: 'Add',
	yes: 'Yes',
	no: 'No',
	addMore: 'Add More'
};

export const table = {
	sNo: 'S. No.',
	redemptionDateTime: 'Redemption Date/Time',
	unRedemptionDateTime: 'Unredemption Date/Time',
	customerHash: 'Customer #',
	promotionId: 'Promotion Id',
	promotionEndDateAndTime: 'Promotion EndDate And Time',
	allowCouponToBeDisplayed: 'Allow Coupon To Be Displayed',
	couponCount: 'Coupon Count',
	actions: 'Actions',
	customerMappedCount: 'Customer mapped count',
	couponId: 'Coupon Id',
	couponType: 'Coupon Type',
	couponStatus: 'Coupon Status',
	couponExpiryDateAndTime: 'Coupon Expiry Date And Time',
	couponLimit: 'Coupon Limit',
	couponRedeemedCount: 'Coupon Redeemed Count',
	couponAmount: 'Coupon Amount',
	redeemAmount: 'Redeem Amount',
	status: 'Status',
	title: 'Title',
	description: 'Description',
	promotion: 'Promotion',
	promotionType: 'Promotion Type',
	benefitType: 'Benefit Type',
	startDateTime: 'Start Date & Time',
	endDateTime: 'End Date & Time',
	createdDateTime: 'Created Date & Time',
	createdBy: 'Created By',
	storeCount: 'Store Count',
	payment: 'Payment',
	action: 'Action',
	modifiedBy: 'Modified By',
	modifiedDateTime: 'Modified Date & Time',
	transactionType: 'Transaction Type',
	transactionDate: 'Transaction Date',
	redeemedCount: 'Redeemed Count',
	pattern: 'Pattern',
	separator: 'Separator',
	fileName: 'File Name',
	createdOn: 'Created On',
	userID: 'User ID',
	requestID: 'Request ID',
	eventType: 'Event Type',
	processedCount: 'Processed Count',
	status_ErrorMessage: 'Status/Error Message',
	noDataInfo: 'No Data available',
	numberOfCoupons: 'Number of Coupons',
	displayCoupons: 'Display Coupons',
	couponNotMappedToCustomerCount: 'Coupon Not Mapped To Customer Count',
	totalCouponRedeemCount: 'Total Coupon Redeem Count',
	businessUnit: 'Business Unit'
};

export const couponManagement = {
	redemptionEmptyMsg: 'No redeem data available',
	modalPopupTitle: 'Coupon Redemption Details',
	transactionFilterTxt: 'Select Type',
	downloadDefaultMsg: 'There are downloads initiated by customer which was successful',
	downloadsFailed: 'Downloads Failed'
};

export const updateModalOffers = {
	title: 'Update Promotion(s) Title / Description',
	updateOfferBtn: 'Update Title/Desc',
	showAllPromoBtn: 'Show All Promotions',
	showInvalidPromoBtn: 'Show Invalid Promotions',
	noInvalidTableMsg:
		'There is no invalid promotions click to Update Changes or click Show all Promotions',
	submitBtn: 'Update Changes'
};

export const errorMsg = {
	commonError: 'Something went wrong',
	wentWrong: 'Something went wrong with the connection!',
	unauthorized: 'Unauthorized',
	refreshTokenError: 'Unable to refresh the user session, kindly reload the browser'
};

const loginForm = {
	username: {
		label: 'User ID',
		placeholder: 'Enter User ID'
	},
	oldPassword: {
		label: 'Old Password',
		placeholder: 'Enter old password'
	},
	newPassword: {
		label: 'New Password',
		placeholder: 'Enter new password'
	},
	confirmPassword: {
		label: 'Confirm Password',
		placeholder: 'Confirm new password'
	}
};

const errorLabel = {
	invalidDateFormat: 'Invalid date format',
	endStartDateNotSame: 'End date and time should not be same as start date and time',
	endDateNotLessCurrentDate: 'End date should not be less than current date',
	endDateNotLessStart: 'End date should not be less than the start date'
};

const changePassword = {
	...loginForm,
	passwordSussesMsg: 'Password Changed Successfully',
	passwordHeading: 'Change your password',
	passwordSubTxt: 'It is a good idea to use a strong password that you don’t use elsewhere.'
};

const generateCouponForm = {
	promoID: {
		label: 'Promotion ID',
		placeholder: 'Promotion ID'
	},
	promoExpiryDate: {
		label: 'Promotion Expiry Date',
		placeholder: 'DD-MM-YYYY HH:mm'
	},
	couponType: {
		label: 'Coupon Type',
		placeholder: 'Coupon Type'
	},
	couponSubType: {
		label: 'Coupon Sub Type',
		placeholder: 'Coupon Sub Type'
	},
	numberOfCoupons: {
		label: 'Number of Coupons',
		placeholder: 'Number of Coupons'
	},
	couponLength: {
		label: 'Coupon Length',
		placeholder: 'Coupon Length',
		helperText: 'Coupon length will be in between 4 to 30'
	},
	pattern: {
		label: 'Pattern',
		name: 'pattern'
	},
	characterSetList: {
		label: 'Character Set',
		placeholder: 'Character Set',
		helperText: 'Code will generate as per the character set'
	},
	couponPrefix: {
		label: 'Prefix Code',
		placeholder: 'Prefix Code'
	},
	sufix: {
		label: 'Suffix Code',
		placeholder: 'Suffix Code'
	},
	couponRedemptionLimit: {
		label: 'Coupon Redemption Limit',
		placeholder: 'Coupon Redemption Limit'
	},
	couponExpiryDate: {
		label: 'Coupon Expiry Date',
		placeholder: 'DD-MM-YYYY HH:mm'
	},
	previewCode: {
		label: 'Preview Code',
		placeholder: 'Preview Code'
	},
	file: {
		label: 'Customer Hash',
		helperText: 'Upload customer hash .csv, .xls, .xlsx file'
	},
	couponCode: {
		label: 'Coupon Code',
		placeholder: 'Coupon Code'
	},
	customerHash: {
		label: 'Customer Hash',
		placeholder: 'Customer Hash'
	}
};

const generateCoupon = {
	formLabels: { ...generateCouponForm },
	generateCoupon: 'Generate Coupon',
	mapCustomerHashBtn: 'Map Customer#',
	searchOptionsPlaceHolder: 'Enter promotion ID',
	generateCouponInfoTitle: 'Information',
	customCouponHelperText: 'Enter custom coupon and hit Enter to add coupon',
	customCouponCodesError: 'Enter custom coupon and hit Enter to add coupon',
	actionBtnGenerateText: 'Click to generate coupon',
	actionBtnViewCouponText: 'Click to view coupons',
	viewCouponsModalTitle: 'Coupons for ',
	actionBtnDownloadCouponText: 'Click to download coupon',
	actionBtnReplicateCouponText: 'Click to replicate coupon',
	actionBtnReplicateCouponDisableText: 'Replication is not allowed on custom coupon',
	documentFileSuccessText: 'Download initiated successfully',
	createCoupon: 'Create coupon',
	addCustomCodeBtnText: 'Click to add custom coupon',
	customCodeErrorText:
		'Provide the Coupon Code, Coupon Expiry Date, Coupon Redemption Limit and Click on Plus button to add Coupon',
	maxPossibleHelper: 'Max possible Coupon combination @num',
	patterLengthError: 'Must be greater or equal to pattern length @num',
	dateFormatError: 'Date format must be @format',
	dateBetweenError: 'Date should be between  @min to @max',
	numberInvalidError: 'Must be number',
	numberBetweenError: 'Must be between @min to @max',
	customCouponsLengthError:
		'Only @maxLimit coupons can be added at a time. Click on Submit button to create custom coupon',
	maxNumCouponsHelper: 'Max Coupon generated in current batch @num',
	noOfCouponsGen: 'No. of Coupons Generated for offer',
	leftOverText: 'Left over Coupon ID possible',
	downloadEmptySelectedWarning: 'Kindly select the row to download',
	alreadyExist: 'Already exist'
};

const couponPatternForm = {
	patternSeparator: {
		label: 'Pattern Separator',
		placeholder: 'Pattern Separator'
	},
	pattern: {
		label: 'Pattern',
		placeholder: 'Pattern'
	}
};

const offerFilterModalForm = {
	promotionId: {
		label: 'Promotion Id',
		placeholder: 'Promotion Id'
	},
	benefitType: {
		label: 'Benefit Type',
		placeholder: 'Benefit Type'
	},
	startDate: {
		label: 'Start Date',
		placeholder: 'DD-MM-YYYY HH:mm'
	},
	endDate: {
		label: 'End Date',
		placeholder: 'DD-MM-YYYY HH:mm'
	},
	genre: {
		label: 'Genre',
		placeholder: 'Genre'
	},
	promotionType: {
		label: 'Promotion Type',
		placeholder: 'Promotion Type'
	},
	brand: {
		label: 'Brand',
		placeholder: 'Select Brand'
	},
	searchOfferId: {
		label: 'Search Offer',
		placeholder: 'Search Offer'
	}
};

const couponPattern = {
	formLabels: { ...couponPatternForm },
	couponPattern: 'Coupon Pattern',
	couponPatternBtnTitle: 'Click to configure the coupon pattern',
	patterHelperTxt: 'Press space to add pattern separator',
	createPattern: 'Create Pattern',
	viewPatterns: 'View Patterns',
	searchOptionsPlaceHolder: 'Enter Coupon Pattern',
	couponPatternsSuccess:
		'Coupon Pattern(s) created successfully, click on view pattern to see Coupon Patterns',
	pattern: 'Pattern',
	found: 'Found',
	bulkDelete: 'Bulk Delete',
	patternsSelected: 'Pattern(s) Selected',
	patternCreatedInfoTitle: 'Information',
	selectedPattedId: 'Selected pattern ID(s)',
	patternGenErrorText: 'Provide the Pattern and Click on Plus button to add Pattern',
	addPatternBtnText: 'Click to add custom coupon',
	minPatternLengthText: 'Minimum @min charters is required',
	patternFormatExist: 'pattern format already exist'
};

const offerFilter = {
	formLabels: { ...offerFilterModalForm }
};

const notification = {
	pageTile: 'Notification Management',
	viewStatusTitle: 'View Status/Error Messages',
	downloadPending: 'Offer download is in progress. Please wait.',
	downloadSuccess: 'There are downloads initiated by customer which was successful',
	fileUploadProgress: 'File Upload in-Progress',
	maxSearchPeriodText:
		'* The notifications are fetched for a period of @maxSearchPeriod days for a given From date/To date',
	fromDateText: 'From Date',
	toDateText: 'To Date',
	toggleOn: 'All User activities',
	toggleOff: 'My Activities',
	fromDateBeforeErrText: 'From Date cannot be after To Date',
	fromDateBtwErrText: 'From date must be on or before current date',
	dateInvalidText: 'Please Enter a valid From date',
	pending: 'PENDING',
	partiallySuccess: 'PARTIALLY SUCCESS',
	success: 'SUCCESS',
	failure: 'FAILURE',
	retrying: 'RETRYING',
	inQueue: 'IN QUEUE',
	requestInProgress: 'Request in-progress'
};

const brands = {
	JAGUAR_AND_LAND_ROVER: 'Jaguar and Land Rover',
	TATA_GREEN_BATTERIES: 'Tata Green Batteries',
	TATA_MOTORS: 'Tata Motors',
	VISTARA: 'Vistara',
	TANISHQ: 'Tanishq',
	TATA_1MG: 'Tata 1mg',
	IHCL: 'IHCL',
	TATA_HOUSING: 'Tata Housing',
	STARBUCKS: 'Starbucks',
	TANEIRA: 'Taneira',
	TATA_CLIQ: 'Tata CLiQ',
	TATA_CLIQ_LUXURY: 'Tata CLiQ Luxury',
	TATA_CLIQ_PALETTE: 'Tata CLiQ Palette',
	TITAN_EYE_PLUS: 'Titan Eye Plus',
	WESTSIDE: 'Westside',
	TATA_AIA: 'Tata AIA',
	TATA_CAPITAL_HOME_LOANS: 'Tata Capital Home Loans',
	TATA_NEU_HDFC_BANK_CREDIT_CARD: 'Tata Neu HDFC Bank Credit Card',
	CROMA: 'Croma',
	TATA_PLAY_FIBER: 'Tata Play Fiber',
	VOLTAS: 'Voltas',
	AIR_INDIA: 'Air India',
	AIR_INDIA_EXPRESS: 'Air India Express'
	// BIGBASKET : 'BigBasket',
	// TATA_STEEL: 'Tata Steel',
};

const brandsCategory = {
	AUTO: 'Auto',
	AIRLINES: 'Airlines',
	JEWELLERY: 'Jewellery',
	HEALTH_WELLNESS: 'Health Wellness',
	HOSPITALITY: 'Hospitality',
	HOUSING: 'Housing',
	OTHER_RETAIL: 'Other Retail',
	FINANCIAL_SERVICES: 'Financial Services',
	ELECTRONICS_AND_APPLIANCES: 'Electronics And Appliances'
};

const offerConfigurator = {
	promoAlreadyAvailed: 'Promotion ID: @promo already availed.'
};

const offerConfiguratorCS = {
	offerFilter,
	programId: common.programId,
	promotionId: 'Offer ID',
	promotionTitle: 'Offer Title',
	promotionDescription: 'Offer Description',
	promotionType: table.promotionType,
	effectiveStartDate: 'Offer start date and time',
	effectiveEndDate: 'Offer end date and time',
	travelStartDate: 'Travel Start Date',
	travelEndDate: 'Travel End Date',
	termsAndConditions: 'Terms & Conditions',
	genre: 'Offer Genre',
	businessUnit: 'Business Unit',
	brandName: 'Brand',
	limitedUse: 'Type of offers', // Type of offers (Limited/Unlimited)
	cohortEligibility: 'Cohort_Eligibility',
	promotionApplicability: 'Offers Applicability',
	promotionApplicabilityLimit: 'Offers Applicability limit',
	benefitType: table.benefitType,
	benefitSubType: 'Benefit Sub Type',
	mov: 'MOV',
	flatRewardValue: 'Reward value, if flat reward',
	percentRewardValue: 'Reward value, if Percentage reward (In %)',
	maxRewardPerTransaction: 'Max Reward Per Transaction',
	isOverlapping: 'Overlapping',
	couponBasedOffer: 'Coupon Based Offer',
	couponType: 'Type of Coupons',
	genericCouponCode: 'Generic Coupon Code 1',
	redemptionLimitPerCard: 'Redemption Limit Per Card',
	minTotalTransactionForOfferTriggerTier1: 'Minimum Total Transaction For Offer Trigger (Tier 1)',
	minTotalTransactionForOfferTriggerTier2: 'Minimum Total Transaction For Offer Trigger (Tier 2)',
	minTotalTransactionForOfferTriggerTier3: 'Minimum Total Transaction For Offer Trigger (Tier 3)',
	minTotalTransactionForOfferTriggerTier4: 'Minimum Total Transaction For Offer Trigger (Tier 4)',
	maxTransactionForOfferEligibility: 'Maximum Transaction For Offer Eligibility',
	minTotalSpendReqForOffrTriggerTier1: 'Minimum Total Spend Req For Offer Trigger (Tier 1)',
	minTotalSpendReqForOffrTriggerTier2: 'Minimum Total Spend Req For Offer Trigger (Tier 2)',
	minTotalSpendReqForOffrTriggerTier3: 'Minimum Total Spend Req For Offer Trigger (Tier 3)',
	minTotalSpendReqForOffrTriggerTier4: 'Minimum Total Spend Req For Offer Trigger (Tier 4)',
	maxTotalSpendForOffrEligibility: 'Maximum Total Spend For Offer Eligibility',
	maxBenefitPerCardAcrossTransaction: 'Maximum Benefit Per Card Across Transaction',
	maxBenefitPerCustomerAcrossTransaction: 'Maximum Benefit Per Customer Across Transaction',
	maxRewardAmountAcrossTransaction:
		'Maximum Reward Amount Across Transaction (Total offer budget)',
	deleteFlag: 'Delete Flag',
	source: 'Source',
	createdOn: 'Created On',
	modifiedOn: 'Modified On',
	modifiedBy: 'Modified By',
	createdUid: 'Created Uid',
	createdBy: 'Created By',
	modifiedUid: 'Modified Uid',
	copyOfferID: 'Copy Offer ID',
	downloadTemplateTitle: 'Click to download the applicable templates for Offer'
};

const segmentManagement = {
	heading: 'Segment Management',
	formLabels: {
		segmentType: {
			label: 'Segment Type',
			placeholder: 'Segment Type'
		},
		segmentName: {
			label: 'Segment Name',
			placeholder: 'Segment Name'
		},
		segmentDescription: {
			label: 'Segment Description',
			placeholder: 'Segment Description'
		}
	},
	table: {
		segmentName: 'Segment Name',
		segmentDescription: 'Segment Description',
		createdBy: 'Created By',
		createdOn: 'Created On',
		action: 'Action'
	},
	viewSegment: 'View Segment',
	addSegment: 'Add Segment',
	searchSegment: 'Search Segment',
	bulkDeleteTitle: 'Bulk Delete Segments',
	deleteSegmentTitle: 'Delete Segment',
	notAllowedTitle: 'Not Allowed'
};

const downloadExlBtn = {
	dataNotFound: 'Data to Export not found.',
	saveAsFileName: 'data',
	buttonLabel: 'Click to download summary',
	buttonTitle: 'Export summary to Excel'
};

const appLabels = {
	common,
	table,
	page: {
		couponManagement,
		changePassword,
		generateCoupon,
		couponPattern,
		notification
	},
	updateModalOffers,
	errorMsg,
	errorLabel,
	brands,
	brandsCategory,
	offerConfigurator,
	offerConfiguratorCS,
	segmentManagement,
	downloadExlBtn
};

export default appLabels;
